import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Card, Link
} from "../layout/Content";
import React from "react";
import {Footer, Header} from "../partial/Navigation";
import {Breadcrumb} from "../layout/Defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookBookmark, faUserShield} from "@fortawesome/free-solid-svg-icons";

export function PrivacyPolicy() {
    return (
        <Layout className="landing-page">
            <Header />
            <Page Grow>
                <Content className="limits wide pad">
                    <Row Gap>
                        <Col xs={24} lg={24}>
                            <Breadcrumb items={[<Link href={"/"} className={""}>OakFrame</Link>,
                                <strong><FontAwesomeIcon fixedWidth icon={faUserShield} className={"muted"}/> Privacy Policy</strong>,
                            ]} />
                        </Col>
                        <Col xs={24}>
                            <Space direction="vertical" Gap>
                                <Title>Privacy Policy</Title>
                                <Paragraph>
                                    At <strong>OakFrame Interactive Ltd</strong>, your privacy is a top priority. This Privacy Policy outlines how we collect, use, and protect your personal information.
                                </Paragraph>
                            </Space>
                        </Col>

                        <Col xs={24} md={12}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Information We Collect</Title>
                                    <ul>
                                        <li>Personal details like name and email address when you create an account or contact us.</li>
                                        <li>Usage data including IP address, browser type, and activity on our platform.</li>
                                        <li>Optional data you provide, such as profile details or uploaded content.</li>
                                    </ul>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={12}>
                            <Card Full Pad className={"active"}>
                                <Space direction="vertical" Gap>
                                    <Title>How We Use Your Information</Title>
                                    <ul>
                                        <li>To provide and enhance our services.</li>
                                        <li>To communicate with you, including updates and promotional offers.</li>
                                        <li>To ensure platform security and compliance with legal obligations.</li>
                                    </ul>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Data Sharing</Title>
                                    <Paragraph>
                                        We do not sell your personal data. Information may be shared with trusted partners to deliver our services or comply with legal requirements.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Your Rights</Title>
                                    <Paragraph>
                                        You have the right to access, correct, or delete your personal data. Please <a href="/contact">contact us</a> for assistance.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Cookie Policy</Title>
                                    <Paragraph>
                                        For information about how we use cookies, please see our <a href="/cookie-policy">Cookie Policy</a> page.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Page>
            <Footer />
        </Layout>
    );
}
