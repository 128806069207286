import React from "react";
import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button,
    Card,
    Input,
    Link,
    Pill,
    Small,
    Separator,
    Highlight,
    Spacer,
} from "./layout/Content";
import { Footer, Header, Navigation } from "./partial/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faBook, faBookBookmark,
    faBookOpen,
    faChartLine,
    faCircleHalfStroke,
    faDiagramSuccessor,
    faDumpsterFire, faFileLines, faFilePdf, faSchool,
    faSignature,
} from "@fortawesome/free-solid-svg-icons";
import {Blockquote, Breadcrumb, Collapsible, InlineIcon, Select, Statistic, Tooltip} from "./layout/Defaults";
import {GetBookModal} from "./part/GetBookModal";
import {faNewspaper} from "@fortawesome/free-regular-svg-icons";

const PublishingPage: React.FC = () => {
    return (
        <Layout>
            <Header />
            <Page Grow className="app-main">
                <Content className={"limits wide pad-h pad-bottom"}>
                    <Row Gap>
                        <Col xs={24} lg={24}>
                            <Breadcrumb items={[<Link href={"/"} className={""}>OakFrame</Link>,<strong><FontAwesomeIcon fixedWidth icon={faNewspaper} className={"muted"}/> Recent Publications</strong>]} />
                        </Col>
                        <Col xs={24}>
                            <Card Full Pad className={"active"}>
                                <Row Gap Full>
                                    <Col xs={24} md={12}>
                                        <div
                                            style={{
                                                backgroundImage: `url("/asset/landing/building-on-unstable-services-cutout-hero.png")`,
                                                height: "100%",
                                                width: "100%",
                                                minHeight: "30vh",
                                                maxWidth: "100%",
                                                backgroundPosition: "50% 25%",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                        ></div>
                                    </Col>
                                    <Col xs={24} md={12} lg={12}>
                                        <Space justify="space-between" direction="vertical" Gap Full>
                                            <Space direction="vertical">
                                                <Space GapSm NoWrap align={"center"} className={"muted"}>
                                                    <strong><FontAwesomeIcon icon={faFilePdf} fixedWidth /></strong>
                                                    <small><strong>Full E-Book</strong> and <strong>PDF</strong> available <strong>Mar. 16, 2025</strong></small>
                                                </Space>
                                                <Space GapSm NoWrap align={"center"} className={"muted"}>
                                                    <strong><FontAwesomeIcon icon={faBook} fixedWidth /></strong>
                                                    <small><strong>Hardcover Edition</strong> available <strong>Oct. 16, 2025</strong></small>
                                                </Space>
                                            </Space>
                                            <Space direction="vertical" GapSm>
                                                <Title Large
                                                ><strong>Building</strong> on <strong><br />Unstable Services</strong>
                                                </Title>
                                                <Paragraph>
                                                    <h1>A Practical Guide to Decoupling, Service Isolation, and Scalability</h1>
                                                </Paragraph>
                                                <Paragraph>
                                                    In an age where software needs to be fast, adaptable, and built to handle
                                                    complex demands, <strong>stable systems</strong> have emerged as a transformative
                                                    approach for modern applications. This book reveals a comprehensive strategy
                                                    for implementing stable system design patterns that balance innovation with
                                                    reliability, scalability with simplicity, and speed with security.
                                                </Paragraph>
                                            </Space>
                                            <Space direction="horizontal" justify="end" GapSm>
                                                <GetBookModal/>
                                                <div><Button type={"ghost"} className={"wide"}>
                                                    <strong>Pre-Order Full Hardcover Now</strong>
                                                </Button></div>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24}>
                            <Space GapSm direction={"vertical"}>
                                <Title Large
                                    style={{
                                        borderTop: "12px solid #c0e0f055",
                                        paddingTop: "40px",
                                        textAlign: "center",
                                    }}
                                >Drive <strong>Transformation</strong> across any <strong>Organization</strong></Title>
                            </Space>
                        </Col>

                        <Col xs={24} md={12}>
                            <Space Gap direction={"vertical"}>
                                <h1>
                                    <span>
                                        <span className={"muted"}>A</span> Technical Leader's Toolkit <span className={"muted"}>for
                                            Building</span> Scalable<span className={"muted"}>,</span> Resilient<span
                                            className={"muted"}>,</span> Stable Systems
                                    </span>
                                </h1>
                                <Paragraph>
                                    Building on Unstable Services is written to help technical leaders make informed decisions
                                    about system design. By analyzing the needs and constraints of systems, you’ll learn to
                                    build and improve technology stacks across generations of lifecycles and sprint iterations.
                                </Paragraph>
                                <Space GapSm>
                                    <Paragraph>
                                        <strong>Personal Development</strong> &mdash; <strong>Startups</strong> &mdash;
                                        <strong>Small-Medium Business</strong> &mdash; <strong>Enterprise</strong>
                                    </Paragraph>
                                </Space>
                            </Space>
                        </Col>

                        <Col xs={24} md={12}>
                            <Space direction={"vertical"} Full justify={"space-around"}>
                                <Space direction={"vertical"} NoWrap GapSm>
                                    <Paragraph>
                                        <em>Get the entire <strong>Part 1</strong> (Chapters 1 - 3) <strong>Pre-Release Sample
                                            E-Book</strong>, which covers:</em>
                                    </Paragraph>
                                    <ol>
                                        <li>
                                            <strong>Introduction to Microservices:</strong>
                                            <br />
                                            Definitions, Core Principles, Evolution from Monoliths
                                        </li>
                                        <li>
                                            <strong>Architecting for Instability:</strong>
                                            <br />
                                            Risk Profiles, Decoupling, Testing at Service Boundaries
                                        </li>
                                        <li>
                                            <strong>Building with Intentional Design:</strong>
                                            <br />
                                            Impact of Unintentional Design Flaws, Business Objectives
                                        </li>
                                    </ol>
                                </Space>
                            </Space>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Card justify={"space-between"} direction={"vertical"} Pad className={"pattern03"}>
                                <Space Gap>
                                    <Title>Master the Art of Building <strong>Resilient Systems</strong></Title>
                                    <p>
                                        Discover how to design, implement, and maintain stable systems in unpredictable
                                        environments. In <strong>Building on Unstable Services</strong>, you'll gain actionable
                                        insights, proven strategies, and real-world solutions for navigating the challenges of
                                        modern service-oriented architectures.
                                    </p>

                                    <Row Gap>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{ backgroundColor: "#a4cbea" }}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faDiagramSuccessor} />
                                                        </div>
                                                    </div>
                                                    <h3>Architecture Resilience</h3>
                                                </Space>
                                            </strong>
                                            <Divider />
                                            <Paragraph>
                                                Learn how to design <strong>resilient microservices</strong> that withstand <em>failure</em>
                                                and <em>scaling challenges</em>, ensuring system stability.
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{ backgroundColor: "#a4cbea" }}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faChartLine} />
                                                        </div>
                                                    </div>
                                                    <h3>Intentional Systems Design</h3>
                                                </Space>
                                            </strong>
                                            <Divider />
                                            <Paragraph>
                                                Build systems with <strong>purpose-driven design</strong> to optimize <em>performance</em>,
                                                <em>scalability</em>, and <em>team productivity</em>.
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{ backgroundColor: "#a4cbea" }}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faSignature} />
                                                        </div>
                                                    </div>
                                                    <h3>Effective QA Lifecycle</h3>
                                                </Space>
                                            </strong>
                                            <Divider />
                                            <Paragraph>
                                                Develop <strong>robust testing pipelines</strong> to catch issues early, ensuring seamless
                                                <em>deployment</em> and <em>user satisfaction</em>.
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{ backgroundColor: "#a4cbea" }}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faCircleHalfStroke} />
                                                        </div>
                                                    </div>
                                                    <h3>Operational Strategies</h3>
                                                </Space>
                                            </strong>
                                            <Divider />
                                            <Paragraph>
                                                Gain insights into managing <strong>unstable services</strong> and implementing <em>proactive
                                                    solutions</em> for long-term success.
                                            </Paragraph>
                                        </Col

>
                                    </Row>

                                    <Link href={"/"}>
                                        Explore the full content of Building on Unstable Services
                                        <FontAwesomeIcon fixedWidth icon={faArrowRight} />
                                    </Link>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24}>
                            <Title
                                style={{
                                    fontWeight: "100",
                                    textAlign: "center",
                                }}
                            >
                                <strong>Insights You Need to Succeed</strong>
                            </Title>
                        </Col>

                        <Col xs={24}>
                            <Row Gap>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Get <strong>Part 1 Free</strong> (Chapters 1 - 3)</h3>
                                            <Paragraph>
                                                <strong>Yes, really!</strong> Download <strong>Part 1</strong> as a PDF via email with <strong>no strings attached</strong>.
                                                Discover the value of this resource and start transforming your systems today.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Build <strong>Reliable</strong> and <strong>Scalable Systems</strong></h3>
                                            <Paragraph>
                                                Stable systems ensure <strong>resilience</strong> and <strong>performance</strong> under stress. By decoupling components
                                                and adopting intentional design, you can maintain <strong>reliability</strong> in unpredictable conditions.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Use <strong>Intentional Design</strong> to Meet <strong>Goals</strong></h3>
                                            <Paragraph>
                                                <strong>Every decision matters</strong>. Intentional design ensures your architecture aligns with
                                                <strong>long-term goals</strong>, reduces unnecessary complexity, and avoids common pitfalls.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Discover <strong>Proven Scaling Strategies</strong></h3>
                                            <Paragraph>
                                                Learn <strong>actionable techniques</strong> to scale without sacrificing performance or reliability.
                                                Manage rapid growth and prepare for future challenges with <strong>confidence</strong>.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Optimize <strong>Systems</strong> and <strong>Advance Your Career</strong></h3>
                                            <Paragraph>
                                                This book equips you with <strong>practical insights</strong> to optimize system performance, impress
                                                stakeholders, and unlock <strong>new opportunities</strong> for career or business growth.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>A <strong>Comprehensive Guide</strong> to Create <strong>Impactful Systems</strong></h3>
                                            <Paragraph>
                                                <strong>Building on Unstable Services</strong> combines real-world expertise with actionable advice.
                                                It’s your ultimate resource for building <strong>resilient</strong> and <strong>scalable systems</strong>.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>


                        <Col xs={24}>
                            <Card Pad>
                                <Space justify={"space-between"} align={"center"} Gap direction={"vertical"}>
                                    <Paragraph> Don’t wait—secure your copy of <strong>Building on Unstable Services</strong> today! Whether you’re ready to dive into the <strong>free Part 1 sample</strong>, pre-order the <strong>full e-book</strong>, or reserve the <strong>hardcover edition</strong>, take the next step to transform your understanding of resilient systems. Click below to choose your preferred version and start building the future of stable systems! </Paragraph>
                                    <GetBookModal/>
                                </Space>

                            </Card>
                        </Col>


                        <Col md={24} lg={12} xl={16} className={"pattern01 rounded"}>
                            <Space align={"center"} justify={"center"} Full>
                                <Row>
                                    <Col xs={24} md={24} lg={16} xl={12}>
                                        <Space direction="vertical">
                                            <Content className={"pad-content"} style={{ padding: "80px 20px" }}>
                                                <Space direction={"vertical"} Full Gap>
                                                    <h3 className={""}>
                                                        <span className={"muted-heavy"}>
                                                            <strong>Researched</strong> and <strong>written</strong> by
                                                            <br />
                                                        </span>
                                                        <strong> Gordon Goodrum</strong>
                                                        <span className={"muted-heavy"}>
                                                            , a leader in technical consulting with over a decade of expertise
                                                            building scalable, resilient systems for businesses worldwide.
                                                        </span>
                                                    </h3>
                                                    <h3 className={""}>
                                                        <span className={"muted-heavy"}>Published by</span>
                                                        <br />
                                                        <strong>OakFrame Interactive Ltd</strong>
                                                        <span className={"muted-heavy"}>.</span>
                                                    </h3>
                                                </Space>
                                            </Content>
                                        </Space>
                                    </Col>
                                </Row>
                            </Space>
                        </Col>

                        <Col md={24} lg={12} xl={8}>
                            <Card Pad className={"rounded pattern04"} Full>
                                <Space direction={"vertical"} Full GapSm justify={"space-around"}>
                                    <Space GapSm direction={"vertical"}>
                                        <img style={{
                                            height:"92pt",
                                            width:"92pt",
                                        }} className={"rounded"} src={"/asset/landing/gordon.png"}/>
                                        <Paragraph>
                                            A work of passion, <strong>Gordon Goodrum</strong> brings over a decade of experience
                                            in deploying hundreds of products and services across nearly every scale. His tailored
                                            approach to <strong>software development</strong> and <strong>technical consulting</strong> informs his ability to develop, launch, and support products and services across a
                                            diverse ecosystem.
                                        </Paragraph>
                                    </Space>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </Content>
                <Footer />
            </Page>
        </Layout>
    );
};

export default PublishingPage;