import React, { useState } from 'react';
import { Button, Card, Content, Input, Paragraph, Small, Space, Title } from "../layout/Content";
import { Modal, Result } from "../layout/Defaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faEnvelopeOpenText,
    faFilePdf,
    faSpinner,
    faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";

export const GetBookModal: React.FC = () => {
    const [modal, setModal] = useState(false);
    const [email, setEmail] = useState('');
    const [allowMarketing, setAllowMarketing] = useState(false);
    const [allowPublishing, setAllowPublishing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const validateEmail = (email:string):any => {
        return !!String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch('https://api.oakframe.org/publishing/signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email,
                    allow_marketing: allowMarketing,
                    allow_marketing_publishing: allowPublishing,
                }),
            });

            const result = await response.json();
            if (response.ok) {
                setSuccess(true);
            } else {
                setError(result.errors?.email || result.error || 'An error occurred.');
            }
        } catch {
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setValidEmail(validateEmail(e.target.value||""))
        if (error) setError(null); // Clear error when the email changes
    };

    return (
        <div>
            <Button
                type={"primary"}
                className={"wide"}
                onClick={() => {
                    setModal(!modal);
                }}
            ><Space GapSm NoWrap>
                <FontAwesomeIcon icon={faFilePdf} fixedWidth /><strong>Download the Part 1 Sample</strong></Space>
            </Button>
            <Modal className={"card active"} visible={modal} onClose={() => setModal(false)} title={<h2><strong>Building on Unstable
                Services</strong> <br/> <FontAwesomeIcon icon={faFilePdf} fixedWidth className={"muted-heavy"}/> Part 1 Sample</h2>}>
                <Content>
                    <Space direction="vertical" Gap>
                        {!success ? (
                            <>

                                <Space direction={"vertical"}>
                                    <Space direction={"vertical"} GapSm>
                                        <Paragraph>
                                            <Small>
                                                <em>
                                                    Enter your email to receive the free <strong>Part 1 Sample</strong> of <strong>Building on Unstable Services</strong> and email communications from <strong>OakFrame</strong>.
                                                </em>
                                            </Small>
                                        </Paragraph>
                                        <Space direction={"horizontal"} GapSm Wide align={"center"}>
                                            <FontAwesomeIcon fixedWidth icon={validEmail?faCheck:faEnvelopeOpenText} className={"muted"}/>
                                            <Input
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="email@address.com"
                                            error={!!error}
                                            grow
                                        /></Space>
                                    </Space>
                                </Space>

                                <Space GapSm direction={"vertical"}>
                                    <Space align="center" GapSm NoWrap>
                                        <input
                                            id={"marketing-release"}
                                            type="checkbox"
                                            checked={allowMarketing}
                                            onChange={() => setAllowMarketing(!allowMarketing)}
                                        />
                                        <label htmlFor="marketing-release">
                                            Keep me updated with <strong>OakFrame News</strong> and <strong>Promotions</strong>.
                                        </label>
                                    </Space>
                                    <Space align="center" GapSm NoWrap>
                                        <input
                                            id={"notify-release"}
                                            type="checkbox"
                                            checked={allowPublishing}
                                            onChange={() => setAllowPublishing(!allowPublishing)}
                                        />
                                        <label htmlFor="notify-release">
                                            Notify me when <strong>Full E-Book</strong> or <strong>Hardcover Edition</strong> is
                                            released.
                                        </label>
                                    </Space>
                                </Space>
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                <Button type="primary" disabled={loading||!validEmail} icon={!validEmail?<FontAwesomeIcon icon={faTriangleExclamation} className={" muted"}/>:((loading)?<FontAwesomeIcon icon={faSpinner} spinPulse/>:<FontAwesomeIcon icon={faEnvelopeOpenText}/>) } className={"wide"} onClick={handleSubmit}>
                                    {validEmail?<b>Get the Free Part 1 Sample</b>:<b>Enter a valid E-Mail Address</b>}
                                </Button>
                            </>
                        ) : (
                            <Space direction={"vertical"} Gap>
                                <Result
                                    status="success"
                                    title="Check your E-Mail Inbox!"
                                    subTitle={
                                        <Paragraph>
                                            We are sending the link to your inbox. Your download link should be there shortly.
                                        </Paragraph>
                                    }
                                />
                            </Space>
                        )}
                    </Space>
                </Content>
            </Modal>
        </div>
    );
};
