import React from 'react';
//import 'oakd/src/Reset.scss';
import './App.scss';
import LandingPage from "./component/Landing";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PageComponent} from "./component/PageComponent";
import Articles from "./component/Articles";
import Reader from "./component/Reader";
import {StyleGuide} from "./component/page/StyleGuide";
import {getPlatform, getWrapper} from "./lib/Wrapper";
import {PrivacyPolicy} from "./component/page/PrivacyPolicy";
import {TermsOfService} from "./component/page/TermsOfService";
import Contact from "./component/page/Contact";
import {NotFound} from "./component/page/NotFound";
import PublishingPage from "./component/Publishing";
import PublishingBuildingOnUnstableServicesPage from "./component/PublishingBuildingOnUnstableServices";
import {CookiesPolicy} from "./component/page/CookiesPolicy";

function App() {
  return (
      <div className={["App", getPlatform(), getWrapper()].join(" ")}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageComponent element={<LandingPage/>}/>}/>
                <Route path="/style" element={<PageComponent element={<StyleGuide/>}/>}/>
                <Route path="/reader" element={<PageComponent element={<Reader/>}/>}/>
                <Route path="/articles" element={<PageComponent element={<Articles/>}/>}/>
                <Route path="/collect" element={<PageComponent element={<Articles/>}/>}/>
                <Route path="/contact" element={<PageComponent element={<Contact/>}/>}/>


                <Route path="/privacy" element={<PageComponent element={<PrivacyPolicy/>}/>}/>
                <Route path="/terms" element={<PageComponent element={<TermsOfService/>}/>}/>
                <Route path="/cookies" element={<PageComponent element={<CookiesPolicy/>}/>}/>


                <Route path="/publishing" element={<PageComponent element={<PublishingPage/>}/>}/>
                <Route path="/publishing/building-on-unstable-services" element={<PageComponent element={<PublishingBuildingOnUnstableServicesPage/>}/>}/>

                <Route path="*" element={<PageComponent element={<NotFound/>}/>}/>

            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
