import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Card, Link
} from "../layout/Content";
import React from "react";
import {Footer, Header} from "../partial/Navigation";
import {Breadcrumb} from "../layout/Defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookBookmark, faCookieBite} from "@fortawesome/free-solid-svg-icons";

export function CookiesPolicy() {
    return (
        <Layout className="landing-page">
            <Header />
            <Page Grow>
                <Content className="limits wide pad">
                    <Row Gap>
                        <Col xs={24} lg={24}>
                            <Breadcrumb items={[<Link href={"/"} className={""}>OakFrame</Link>,
                                <strong><FontAwesomeIcon fixedWidth icon={faCookieBite} className={"muted"}/> Cookies Policy</strong>,
                            ]} />
                        </Col>
                        <Col xs={24}>
                            <Space direction="vertical" Gap>
                                <Title>Cookies Policy</Title>
                                <Paragraph>
                                    At <strong>OakFrame Interactive Ltd</strong>, we use cookies to enhance your browsing experience and provide a personalized service. This Cookies Policy outlines what cookies are, how we use them, and your control over their usage.
                                </Paragraph>
                            </Space>
                        </Col>

                        <Col xs={24} md={12}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>What Are Cookies?</Title>
                                    <Paragraph>
                                        Cookies are small files stored on your device to improve website functionality and user experience. They allow websites to remember your preferences, login sessions, and browsing activities.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={12}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Why We Use Cookies</Title>
                                    <Paragraph>
                                        We use cookies to:
                                    </Paragraph>
                                    <ul>
                                        <li>Understand user preferences and improve our website.</li>
                                        <li>Provide personalized experiences based on your interactions.</li>
                                        <li>Enhance security and analyze website traffic.</li>
                                    </ul>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad className={"active"}>
                                <Space direction="vertical" Gap>
                                    <Title>Types of Cookies</Title>
                                    <Paragraph>
                                        We use different types of cookies, including:
                                    </Paragraph>
                                    <ul>
                                        <li><strong>Essential Cookies:</strong> Necessary for website operation.</li>
                                        <li><strong>Performance Cookies:</strong> Help us analyze website performance.</li>
                                        <li><strong>Functional Cookies:</strong> Enable personalization.</li>
                                    </ul>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Your Choices</Title>
                                    <Paragraph>
                                        You can manage cookie preferences through browser settings. Disabling cookies may affect website functionality but will not limit your access.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad className={""}>
                                <Space direction="vertical" Gap>
                                    <Title>Learn More</Title>
                                    <Paragraph>
                                        For detailed information about cookies, visit <a href="https://allaboutcookies.org">All About Cookies</a>. If you have questions about our Cookies Policy, <a href="/contact">contact us</a>.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Page>
            <Footer />
        </Layout>
    );
}
