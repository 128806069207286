import React, { useState } from 'react';
import {Button, Col, Input, Layout, Page, Row, Small, Space, Title} from "../layout/Content";
import { Header, Footer } from "../partial/Navigation";
import {Result} from "../layout/Defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const Contact: React.FC = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' }); // Clear error for the field
    };

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const response = await fetch('https://api.oakframe.org/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                setSuccess(true);
                setFormData({ name: '', email: '', message: '' });
            } else {
                setErrors(result.errors || {});
            }
        } catch (error) {
            setErrors({ general: 'An error occurred. Please try again later.' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout className="landing-page">
            <Header />
            <Page Grow>
                <Space GapSm direction="vertical" className="limits wide pad">
                    <Title>Contact Us</Title>
                    {!success ? (
                            <Space GapSm direction="vertical">
                                <Input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    placeholder="Your Name"
                                    onChange={handleChange}
                                    error={!!errors.name}
                                />
                                {errors.name && <Small className={"error"}>{errors.name}</Small>}
                                <Input
                                    type={"email"}
                                    name="email"
                                    value={formData.email}
                                    placeholder="Email Address"
                                    onChange={handleChange}
                                    error={!!errors.email}
                                />
                                {errors.email && <Small className={"error"}>{errors.email}</Small>}
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    placeholder="Tell us about your project"
                                    onChange={handleChange}
                                    className={errors.message ? 'error' : ''}
                                    style={{ width: '100%', height: '120px', padding: '8px' }}
                                />
                                {errors.message && <Small className={"error"}>{errors.message}</Small>}
                                <Button type="primary" icon={loading?<FontAwesomeIcon icon={faSpinner} spinPulse/>:""} onClick={handleSubmit} disabled={loading}>
                                    {loading?"Submitting...":"Submit Form"}
                                </Button>
                                {errors.general && <Small className={"error"}>{errors.general}</Small>}
                            </Space>

                    ) : (
                        <Space GapSm direction="vertical">
                            <Result
                                status="success"
                                title="Thank you for reaching out!"
                                subTitle="Your message has been successfully sent. We’ll get back to you soon."
                            />
                            <Button type="default" onClick={() => setSuccess(false)}>
                                Send Another Message
                            </Button>
                        </Space>
                    )}
                </Space>
            </Page>
            <Footer />
        </Layout>
    );
};

export default Contact;
