import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Card, Link
} from "../layout/Content";
import React from "react";
import {Footer, Header} from "../partial/Navigation";
import {Breadcrumb} from "../layout/Defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookBookmark, faGavel} from "@fortawesome/free-solid-svg-icons";

export function TermsOfService() {
    return (
        <Layout className="landing-page">
            <Header />
            <Page Grow>
                <Content className="limits wide pad">
                    <Row Gap>
                        <Col xs={24} lg={24}>
                            <Breadcrumb items={[<Link href={"/"} className={""}>OakFrame</Link>,
                                <strong><FontAwesomeIcon fixedWidth icon={faGavel} className={"muted"}/> Terms of Service</strong>,
                            ]} />
                        </Col>
                        <Col xs={24}>
                            <Space direction="vertical" Gap>
                                <Title>Terms of Service</Title>
                                <Paragraph>
                                    Welcome to <strong>OakFrame Interactive Ltd</strong>. By using our platform, you agree to the following terms and conditions. Please read them carefully.
                                </Paragraph>
                            </Space>
                        </Col>

                        <Col xs={24} md={12}>
                            <Card Full Pad className={"active"}>
                                <Space direction="vertical" Gap>
                                    <Title>User Responsibilities</Title>
                                    <ul>
                                        <li>Users must be at least 18 years old to create an account and use our services.</li>
                                        <li>Users must ensure that the information they provide is accurate and up to date.</li>
                                        <li>Users must not upload content that infringes on the rights of others, is illegal, or violates our community guidelines.</li>
                                    </ul>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={12}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Content Ownership</Title>
                                    <Paragraph>
                                        Users retain ownership of the content they upload, but grant <strong>OakFrame Interactive Ltd</strong> a non-exclusive, royalty-free license to use, display, and distribute the content on our platform.
                                    </Paragraph>
                                    <Paragraph>
                                        We reserve the right to remove any content or terminate any account that violates these terms.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Platform Liability</Title>
                                    <Paragraph>
                                        <strong>OakFrame Interactive Ltd</strong> is not liable for any damages resulting from the use of our platform. Users agree to use the platform at their own risk.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Service Availability</Title>
                                    <Paragraph>
                                        We strive to ensure continuous availability of our services but do not guarantee uninterrupted operation due to maintenance or unforeseen issues.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card Full Pad>
                                <Space direction="vertical" Gap>
                                    <Title>Amendments</Title>
                                    <Paragraph>
                                        These terms may be updated periodically. Continued use of the platform after updates signifies your agreement to the revised terms.
                                    </Paragraph>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Page>
            <Footer />
        </Layout>
    );
}
