import {useUserProfileContext} from "../lib/UserContext";
import {useEffect, useState} from "react";

export const PageComponent = (props: { element: any, before?: any }) => {
    const {queryUserProfile, getUserProfile, hasQueriedUserProfile} = useUserProfileContext();
    const [modal, setModal] = useState<number>(1);


    const accountSetupModal = () => {
        if (!hasQueriedUserProfile()) {
            return false;
        }
        const user = getUserProfile();
        if (user && ((Date.now() - modal) > 5*60*1000)) {
            if (user.attributes && user.attributes.userDetails) {

            } else {
                setModal(0);
            }
        }
    }


    useEffect(() => {
        //  document.addEventListener("mousedown", downHandler);
        // document.addEventListener("mouseup", upHandler);
        //fetchUserProfile();
        const userRefresh = setInterval(() => {
            //     fetchUserProfile();
        }, 8000);

        return () => {
            // document.removeEventListener("mousedown", downHandler);
            //document.removeEventListener("mouseup", upHandler);
            clearInterval(userRefresh)
        }
    }, []);

    const defer = async () => {
        await queryUserProfile();
        accountSetupModal();
    }


    //const [top, setTop] = useState(0);
    useEffect(() => {
        defer();
        // track pageview with gtag / react-ga / react-ga4, for example:
        console.log(window.location.pathname);
        //ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

        // @ts-ignore
        document.getElementById("root").scrollTo(0, 0);
    }, [window.location, props]);


    return (<div>
        {props.element}
    </div>)
}