import React, { useEffect, useState } from "react";
import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Button,
    Card, Link,
} from "./layout/Content";
import { Footer, Header } from "./partial/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBookBookmark, faDownload, faFilePdf, faSpinner} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import {GetBookModal} from "./part/GetBookModal";
import {Breadcrumb} from "./layout/Defaults";

const PublishingBuildingOnUnstableServicesPage: React.FC = () => {
    const [tokenValid, setTokenValid] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode | null>(null);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                setTokenValid(false);
                setErrorMessage("No token provided.");
                setLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://api.oakframe.org/publishing/verify?token=${token}`);
                const result = await response.json();

                if (response.ok) {
                    setTokenValid(true);
                } else {
                    setTokenValid(false);
                    setErrorMessage(result.error || "Invalid or expired token.");
                }
            } catch (error) {
                setTokenValid(false);
                setErrorMessage("An error occurred while validating your token. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        validateToken();
    }, [token]);

    const handleDownload = () => {
        if (!tokenValid || !token) return;
        window.location.href = `https://api.oakframe.org/publishing/download/building-on-unstable-services-sample.pdf?token=${token}&attachment=true`;
    };

    const handleOpenPDF = () => {
        if (!tokenValid || !token) return;
        window.open(`https://api.oakframe.org/publishing/download/building-on-unstable-services-sample.pdf?token=${token}`, "_blank");
    };

    return (
        <Layout>
            <Header />
            <Page Grow className="app-main">
                <Content className={"limits wide pad-h pad-bottom"}>
                    <Row Gap>
                        <Col xs={24} lg={24}>
                            <Breadcrumb items={[<Link href={"/"} className={""}>OakFrame</Link>,
                                <Link href={"/publishing"} className={""}>Recent Publications</Link>,
                                <strong><FontAwesomeIcon fixedWidth icon={faBookBookmark} className={"muted"}/> Building on Unstable Services</strong>,
                            ]} />
                        </Col>
                        <Col xs={24} lg={12}>
                            <Card Full Pad className={"pattern02"}>
                                <Space direction={"vertical"} justify={"space-between"} Full>
                                    <Row Full>
                                        <Col xs={24}>
                                            <div
                                                style={{
                                                    backgroundImage: `url("/asset/landing/building-on-unstable-services-cutout-hero.png")`,
                                                    height: "100%",
                                                    width: "100%",
                                                    minHeight: "300px",
                                                    maxWidth: "100%",
                                                    backgroundPosition: "50% 50%",
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat",
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Space>
                            </Card>
                        </Col>

                        <Col xs={24} md={12} lg={12}>
                            <Space justify="space-between" direction="vertical" Gap Full>
                                <Space direction="vertical" GapSm>
                                    <Title Large>
                                        <strong>Building</strong> on <strong><br />Unstable Services</strong>
                                    </Title>
                                    <Paragraph>
                                        <h2>
                                            Official Download for the <strong>Part 1 Sample</strong>: Your practical guide
                                            to decoupling, service isolation, and scalability.
                                        </h2>
                                    </Paragraph>
                                </Space>

                                {loading ? (
                                    <Card Pad className={"info disabled"}>
                                        <Paragraph><FontAwesomeIcon icon={faSpinner} spinPulse fixedWidth /> Validating your token... Please wait.</Paragraph>
                                    </Card>
                                ) : tokenValid === false ? (
                                    <Card Pad className={"error"}>
                                        <Space direction={"vertical"} GapSm>
                                        <Paragraph style={{ color: "red" }}>{errorMessage} Click below to get a new link sent to your E-Mail.</Paragraph>
                                            <GetBookModal/>
                                        </Space>
                                    </Card>
                                ) : (
                                    <Card Pad>
                                        <Space direction={"vertical"} GapSm>
                                        <Paragraph>Click a button below to download or open the PDF on your device.</Paragraph>
                                    <Space direction="horizontal" justify="end" GapSm>
                                        <div>
                                            <Button
                                                type={"primary"}
                                                className={"wide lg"}
                                                onClick={handleDownload}
                                            >
                                                <FontAwesomeIcon icon={faDownload} fixedWidth /> <strong>Download PDF to Device</strong>
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                type={"ghost"}
                                                className={"wide"}
                                                onClick={handleOpenPDF}
                                            >
                                                <FontAwesomeIcon icon={faFilePdf} fixedWidth /> <strong>Open PDF in Browser</strong>
                                            </Button>
                                        </div>
                                    </Space></Space>
                                        </Card>
                                )}
                            </Space>
                        </Col>

                        <Col xs={24}>
                            <Space GapSm direction={"vertical"}>
                                <Title Large
                                    style={{
                                        borderTop: "12px solid #c0e0f055",
                                        paddingTop: "40px",
                                        textAlign: "center",
                                    }}
                                >
                                    Drive <strong>Transformation</strong> across any <strong>Organization</strong>
                                </Title>
                            </Space>
                        </Col>

                        <Col xs={24}>
                            <Row Gap>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Unlock Powerful Insights</h3>
                                            <Paragraph>
                                                Download <strong>Part 1</strong> as a free PDF and discover how to transform
                                                your systems with <strong>no strings attached</strong>. Start driving change
                                                today.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Build Resilient Systems</h3>
                                            <Paragraph>
                                                Learn strategies for <strong>resilience</strong> and <strong>scalability</strong>
                                                under real-world stress. Design for reliability in the face of unpredictable challenges.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>

                                <Col xs={24} md={8}>
                                    <Card Full Pad className={"secondary"}>
                                        <Space direction={"vertical"} justify={"space-between"} Full GapSm>
                                            <h3>Comprehensive Expertise</h3>
                                            <Paragraph>
                                                With actionable advice and practical experience, <strong>Building on Unstable Services</strong>
                                                is your guide to creating <strong>impactful systems</strong> that last.
                                            </Paragraph>
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Content>
                <Footer />
            </Page>
        </Layout>
    );
};

export default PublishingBuildingOnUnstableServicesPage;
